<template lang="pug">
    section#darkText
        .wrapper
            h2 A sua segunda casa com serviços de hotelaria à disposição.
            p
                |Quem tem uma segunda casa sabe que ela gera custos e exige cuidados o ano inteiro. Mesmo que seja usada só de vez em quando. Agora, imagine ter essa segunda casa sem se preocupar com manutenção, imprevistos e reformas, e com custos fixos muito menores.
            p
                |GOSTOU? TEM MAIS: imagine que ela é um imóvel de altíssimo padrão, podendo estar localizado na praia, no campo, na montanha ou até mesmo na neve; com estrutura completa de lazer e diversos serviços que vão fazer você ir para a sua segunda casa apenas para aproveitar.
</template>

<script>
export default {
    name: "section-dark-text",
}
</script>

<style lang="stylus" scoped src="./DarkText.styl"></style>