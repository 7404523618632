<template lang="pug">
    section#video
        .thumb-video(@click="$store.commit('setAsideVideo', video)")
            img(:src="require('@images/home/thumb-video.jpg')")
            button
                FontAwesomeIcon(:icon="icons.faCirclePlay")
        .text
            h2
                |CA
                span 2
                |A.
                br
                |Seu novo jeito de 
                |investir e viver a vida.
            p
                |Com a CA2A você tem a oportunidade única de adquirir um imóvel com muito lazer, 
                |conforto, suporte e serviços de rede hoteleira, tanto para aproveitar com a sua 
                |família quanto para alugar para outros hóspedes.
            button.roundedButton(@click="$store.commit('setAsideVideo', video)") Assista ao Vídeo
</template>

<script>
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "section-video",
    data() {
        return {
            icons: {
                faCirclePlay
            },
            video: require('@assets/videos/ca2a_manifesto.mp4')
        }
    }
}
</script>

<style lang="stylus" scoped src="./Video.styl"></style>