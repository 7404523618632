import { render, staticRenderFns } from "./BannerGaleria.vue?vue&type=template&id=a0d7ea30&scoped=true&lang=pug"
import script from "./BannerGaleria.vue?vue&type=script&lang=js"
export * from "./BannerGaleria.vue?vue&type=script&lang=js"
import style0 from "./BannerGaleria.styl?vue&type=style&index=0&id=a0d7ea30&prod&lang=stylus&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0d7ea30",
  null
  
)

export default component.exports