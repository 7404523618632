<template lang="pug">
	BannerGaleriaTemplate#bannerGaleria(:ativo.sync="ativo", :length="banners.length")
		ul.banners
			li(v-for="banner, index in banners", :class="{'ativo': index == ativo}").banner
				div.title
					h2(v-html="banner.title")
					p(v-html="banner.text", v-if="banner.text")
				div.img
					img(:src="banner.img")
</template>

<script>
import BannerGaleriaTemplate from '@components/BannerGaleriaTemplate/BannerGaleriaTemplate';

export default {
  name: 'section-banner-galeria',
  components: {
    BannerGaleriaTemplate
  },
  data() {
    return {
      ativo: 0,
      banners: [
        {
          title: 'Tayayá Porto Rico',
          text: 'Tayayá Porto Rico Lançamento da Fase 2, com projeto da arquiteta Juliana Meda, móveis Artefacto e a melhor vista para o Rio Paraná.',
          img: require('@images/home/galeria/banner_tayaya.jpg')
        },
        {
          title: 'Morro dos anjos',
          text: 'A primeira praia artificial do interior do Paraná, águas quentes, lazer e diversão o ano inteiro. Localizado ao lado do Santuário São Miguel Arcanjo.',
          img: require('@images/home/galeria/banner_mda.jpg')
        },
        {
          title: 'Patrimônio familiar: felicidade que passa de geração em geração.',
          text: 'Faça um investimento que irá beneficiar sua família. Além de ganho financeiro, você tem um clube de lazer para usar a vida inteira ao lado de quem ama.',
          img: require('@images/home/galeria/banner2.jpg')
        },
        {
          title: 'Segurança, rentabilidade e liquidez<br><br>Vale investir.<br>Vale viver.',
          text: 'Aproveite essa bela opção de investimento com empreendimentos localizados em regiões com fluxo intenso de turistas, taxas de ocupacão garantidas e projetos de altíssimo padrão',
          img: require('@images/home/galeria/banner3.jpg')
        }
      ]
    }
  }

}
</script>

<style lang="stylus" scoped src="./BannerGaleria.styl"></style>
