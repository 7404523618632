<template lang="pug">
section#investments
    h2
        span Descubra as melhores opções de
        |investimento para você
    .investments
        Empreendimento(:contents="investment", v-for="investment in investments", :key="investment.title").investment
</template>

<script>
import Empreendimento from '@components/ItemEmpreendimento/ItemEmpreendimento.vue';

export default {
  name: 'section-investments',
  components: {
    Empreendimento
  },
  data() {
    return {
      investments: [
        {
          title: 'Morro dos anjos',
          link: {
            external: false,
            link: {
              name: 'Empreendimento',
              params: { slug: 'morro-dos-anjos' }
            }
          },
          // endereco: "Rodovia BR-369 KM 57 Bandeirantes/PR.",
          diferenciais: [
            'Localização estratégica', 'Estrutura completa de lazer'
          ],
          logo: require('@svgs/morro-dos-anjos.svg'),
          image: require('@images/home/investments/morro-dos-anjos.jpg')
        },
        {
          title: 'Tayayá Ecoview',
          link: {
            external: false,
            link: {
              name: 'Empreendimento',
              params: { slug: 'tayaya-ecoview' }
            }
          },
          // endereco: "Prolongamento da rodovia LZ 412, KM 4, Riberião Claro/PR",
          diferenciais: [
            'Casas de alto padrão', '193 m² | 3 suítes'
          ],
          logo: require('@svgs/tayaya_ecoview.svg'),
          image: require('@images/home/investments/tayaya-view.jpg')
        },
        {
          title: 'Tayayá Aqua Resort',
          link: {
            external: false,
            link: {
              name: 'Empreendimento',
              params: { slug: 'tayaya-aqua-resort' }
            }
          },
          // endereco: "Prolongamento da rodovia LZ 412, KM 4, Riberião Claro/PR",
          diferenciais: [
            'Um dos melhores resorts do Brasil', 'Apartamentos com vista para o lago'
          ],
          logo: require('@svgs/tayaya_aquaresort.svg'),
          image: require('@images/home/investments/tayaya-angradoce.jpg')
        },
        {
          title: 'Tayayá Porto Rico',
          link: {
            external: false,
            link: {
              name: 'Empreendimento',
              params: { slug: 'tayaya-porto-rico' }
            }
          },
          // endereco: "Estrada Beira Rio, Porto São José São Pedro Do Paraná/PR",
          diferenciais: [
            'Casas e apartamentos de 97 a 345 m²', '2 a 5 dormitórios'
          ],
          logo: require('@svgs/tayaya_portorico.svg'),
          image: require('@images/home/investments/tayaya-portorico.jpg')
        }
      ]
    }
  }
}
</script>

<style lang="stylus" scoped src="./Investments.styl"></style>
