import { render, staticRenderFns } from "./Investments.vue?vue&type=template&id=0c2d2659&scoped=true&lang=pug"
import script from "./Investments.vue?vue&type=script&lang=js"
export * from "./Investments.vue?vue&type=script&lang=js"
import style0 from "./Investments.styl?vue&type=style&index=0&id=0c2d2659&prod&lang=stylus&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c2d2659",
  null
  
)

export default component.exports