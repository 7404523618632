<template lang="pug">
	main#home
		BannerGaleria
		Video
		DarkText
		Investments
		AsideVideo(v-if="$store.state.asideVideo != null")
</template>

<script>
import BannerGaleria from '@sections/Home/BannerGaleria/BannerGaleria'
import Video from '@sections/Home/Video/Video'
import DarkText from '@sections/Home/DarkText/DarkText'
import Investments from '@sections/Home/Investments/Investments'
import AsideVideo from '@components/AsideVideo/AsideVideo'

export default {
	name: 'view-home',
	components: {
		BannerGaleria,
		Video,
		DarkText,
		Investments,
		AsideVideo
	},
	metaInfo() {
		return {
			title: 'CA2A | Home',
			meta: [
				{ name: 'description', content: 'A casa da segunda casa.' },
			],
		}
	},
}
</script>

<style lang="stylus" scoped src="./Home.styl"></style>
